import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import * as insightsActions from '../../../store/actions/insights';
import * as scaleActions from '../../../store/actions/scale';
import * as customerActions from '../../../store/actions/customers';
import { apiMetrics, keyCrypto } from "../../../config"
import { i18n } from '../../../translate/i18n';
import { isMobile } from "react-device-detect";
import { debounce } from 'lodash';
import ProductsInfo from '../../../components/Insights/ProductsInfo';
import { Box, Button, CircularProgress, Stack, Tab, Typography } from '@mui/material';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import LockIcon from '@mui/icons-material/Lock';
import { FCTabs, FCTextField } from 'components-fullcomm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProductsOrdersInfo = (props) => {
  const [value, setValue] = useState(0);
  const [replace, setReplace] = useState(false);
  const [noInstall, setNoInstall] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 'yyyy-MM-dd'));
  const [loading, setLoading] = useState(true);
  const [sessionData, setSessionData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderValues, setOrderValues] = useState([]);
  const [ordersDates, setOrdersDates] = useState([]);
  const [orderPaymentStatus, setOrderPaymentStatus] = useState([]);
  const [shippingOrdersData, setShippingOrdersData] = useState([]);
  const [conversionData, setConversionData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const debouncedSearch = useCallback(
    debounce(async (platformDomain) => {
      setLoading(true);
      props.fetchTokenInsights(platformDomain)
      try {
        const config_request = {
          method: "get",
          url: `${apiMetrics}/session/offline_${platformDomain}`,
          headers: {
            'fullcomm-token': keyCrypto
          }
        };
        const result = (await axios(config_request)).data;
        if (!result?.id) {
          setNoInstall(true)
          setLoading(false)
      } else {
          setNoInstall(false)
      }
      } catch (error) {
        setLoading(false);
        return undefined;
      }
    }, 500),
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const debouncedGetCurvesAndOrders = useCallback(
    debounce(async (token, start, end) => {
      props.getProductsInfo(token, start, end);
    }, 1500),
    []
  );

  useEffect(() => {
    setLoading(true);
    props.getViewCustomer(props.shop);
  }, [props.shop])

  useEffect(() => {
    if (props.token) {
      debouncedGetCurvesAndOrders(props.token, startDate, endDate)
    }
  }, [props.token])

  useEffect(() => {
    if (props.customer) {
      if(props.customer.platformDomain === "experiencia-checkstore.myshopify.com") {
        setReplace(true);
        props.customer.platformDomain = "care-natural-beauty-cosmeticos.myshopify.com";
      }
      debouncedSearch(props.customer.platformDomain);
    }
  }, [props.customer])

  useEffect(() => {
    setLoading(false);
  }, [props.productsInfo])

  
  return (
    <Box className="dashboard">
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.sales')}</Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="end" spacing={2} mt={2} >
        <FCTextField
          value={startDate} 
          onChange={e => setStartDate(e.target.value)} 
          id="date" label={i18n.t('insight.startDate')} 
          type="date" 
          InputLabelProps={{ shrink: true }} 
        />
        <FCTextField
          value={endDate} 
          onChange={e => setEndDate(e.target.value)} 
          id="date" 
          label={i18n.t('insight.endDate')} 
          type="date" 
          InputLabelProps={{ shrink: true }} 
        />
        <LoadingButton onClick={() => {
          setLoading(true);
          debouncedGetCurvesAndOrders(props.token, startDate, endDate);
        }} 
        loading={loading} 
        className='btn-white medium mb-1'
        variant="contained" 
      > 
        {i18n.t('insight.search')}
      </LoadingButton>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <FCTabs
          value={value} 
          onChange={handleChange} 
          aria-label="basic tabs example" 
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons={isMobile ? "on" : "off"}
        >
          <Tab label="Potencial elevado" />
          <Tab label="Como melhorar" />
          <Tab label="Não está vendendo" />
          <Tab label="Frequentemente abandonado" />
        </FCTabs>
      </Box>
      {
        loading ?(
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {noInstall ? (
              <Box fullWidth style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Typography variant="h6" style={{ marginTop: '5px', display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
                  <LockIcon />
                  {i18n.t('dashboard.unLockData')}
                </Typography>
                <Typography variant="body2" style={{ marginTop: '5px' }}>
                  {i18n.t('dashboard.textNoInstall')}
                </Typography>
                <br />
                <a href='https://apps.shopify.com/metricas-fullcomm?locale=pt-BR' target='_blank'>
                  <Button className='btn-action' variant='contained'>
                    {i18n.t('dashboard.installInShopify')}
                  </Button>
                </a>
              </Box>
              ) : (
                <>
                  <CustomTabPanel value={value} index={0}>
                    <ProductsInfo productsInfo={props.productsInfo?.high_potencial} tab={0}/>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <ProductsInfo productsInfo={props.productsInfo?.how_to_improve} tab={1}/>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <ProductsInfo productsInfo={props.productsInfo?.not_selling} tab={2}/>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <ProductsInfo productsInfo={props.productsInfo?.frequently_abandoned} tab={3}/>
                  </CustomTabPanel>
                </>
              )}
          </>
        )
      }

    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  productsInfo: state.insight.productsInfo,
  ordersInfo: state.insight.ordersInfo,
  shippingOrders: state.scale.smartShippingOrders,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTokenInsights: (platformDomain) => dispatch(insightsActions.fetchToken(platformDomain)),
  fetchTokenShipping: () => dispatch(scaleActions.fetchToken()),
  getViewCustomer: (shop) => dispatch(customerActions.getViewCustomer(shop)),
  getProductsInfo: (token, start, end) => dispatch(insightsActions.getProductsInfo(token, start, end)),
  getOrdersInfo: (token, start, end) => dispatch(insightsActions.getOrdersInfo(token, start, end)),
  getOrderShippingValue: (start, end) => dispatch(scaleActions.getOrderShippingValue(start, end)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsOrdersInfo);