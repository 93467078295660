import React, { useEffect, useState } from 'react';
import { i18n } from '../../translate/i18n';
import { Table, TableBody, TableCell, TableRow, Tooltip, TableSortLabel } from '@mui/material';
import { FCTableContainer, FCTableHeader } from 'components-fullcomm';

const ProductsInfo = ({ productsInfo, tab }) => {
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('asc');
  const [sortedData, setSortedData] = useState(productsInfo);
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
      if (orderBy) {
          const data = [...productsInfo];
          function getNestedPropertyValue(obj, path) {
            const properties = path.split('.');
            return properties.reduce((acc, property) => (acc && acc[property] !== undefined ? acc[property] : undefined), obj);
          }
          
          data.sort((a, b) => {
            const valueA = getNestedPropertyValue(a, orderBy);
            const valueB = getNestedPropertyValue(b, orderBy);
          
            if (order === 'asc') {
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
              return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
          });
          
          setSortedData(data);
      } else {
          setSortedData(productsInfo);
      }
  }, [orderBy, order, productsInfo]);

  return (
    <FCTableContainer>
      <Table>
        <FCTableHeader>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'title'}
                direction={order}
                onClick={() => handleSort('title')}
              >
                {i18n.t('curveABC.tableHeaders.title')}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'sessions.percentage'}
                direction={order}
                onClick={() => handleSort('sessions.percentage')}
              >
                Sessões
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'added_to_cart.relative'}
                direction={order}
                onClick={() => handleSort('added_to_cart.relative')}
              >
                Adicionado ao carrinho
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'conversion.relative'}
                direction={order}
                onClick={() => handleSort('conversion.relative')}
              >
                Conversão
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'quantity'}
                direction={order}
                onClick={() => handleSort('quantity')}
              >
                Qnt
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </FCTableHeader>
        <TableBody>
          {sortedData?.map(product => {
            return (
              <TableRow style={{ cursor: 'pointer' }} key={product.variant_id}>
                <TableCell>
                  {
                    product.image && (
                      <a href={product.image} target="_blank" rel="noreferrer">
                        <img src={product.image} alt={product.title} style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '0.5rem' }} />
                      </a>
                    )
                  }
                  {product.title?.length > 35 ? <Tooltip title={product.title}><span>{product.title?.substr(0, 35) + '...'}</span></Tooltip> : product.title}</TableCell>
                <TableCell>{product.sessions.unique} ({product.sessions.percentage?.toFixed(2)}%)</TableCell>
                {
                  tab === 3 ? 
                  <TableCell>{product.added_to_cart?.amount}</TableCell>
                  :
                  <TableCell>{product.added_to_cart?.relative?.toFixed(2)}%</TableCell>
                }
                <TableCell>{product.conversion?.relative?.toFixed(2)}%</TableCell>
                <TableCell>{product.quantity}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </FCTableContainer>
  )
}

export default ProductsInfo;
