import React, { useState, useEffect } from 'react';

import { Typography, Collapse, List } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { i18n } from '../../translate/i18n';
import { Icon } from '@iconify/react';
import { userPermissionCheck } from '../../pages/Sprint/utils/userPermissionCheck';
import TopicIcon from '@mui/icons-material/Topic';

const Scale = ({ user, MenuLink, shopDetails }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('scaleSidebar') === 'false' ? false : true);

    const handleToggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        localStorage.setItem('scaleSidebar', isOpen);
    }, [isOpen]);

    return (
        <div>
            <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                {i18n.t('components.sideBar.scale.operation')}
            </Typography>
            <Collapse in={isOpen}>
                <List component="nav">
                    <MenuLink to="/logistics" Icon={WidgetsIcon}>{i18n.t('components.sideBar.scale.logistic')}</MenuLink>
                    <MenuLink to="/smart-shipping" Icon={LocalShippingIcon}>{i18n.t('components.sideBar.scale.smartShipping')}</MenuLink>
                    <MenuLink to="/insights/inventory" Icon={InventoryOutlinedIcon}>{i18n.t('components.sideBar.scale.inventory')}</MenuLink>
                    <MenuLink to="/insights/sales" Icon={ShoppingBagIcon}>{i18n.t('components.sideBar.scale.products')}</MenuLink>
                    {
                        shopDetails?.boteriaId &&
                        <MenuLink to="/boteria" Icon={SmartToyOutlinedIcon}>Boteria</MenuLink>
                    }
                    {
                        shopDetails?.tokenPlatinum &&
                        <MenuLink to="/orders" Icon={ShoppingCartIcon}>{i18n.t('components.sideBar.scale.orders')}</MenuLink>
                    }
                    {
                        userPermissionCheck(user, ["view_document"]) && (
                            <MenuLink to="/documents/scale" Icon={TopicIcon}>{i18n.t('documents.index.title')}</MenuLink>
                        )
                    }
                    {/* <MenuLink to="/service" Icon={PeopleIcon}>Atendimento</MenuLink> */}
                </List>
            </Collapse>
        </div>
    );
};

export default Scale;
