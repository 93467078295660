import {
  Box,
  Table,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/action";
import * as actions2 from "../../store/actions/contracts";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { toast } from "react-toastify";
import OptionsButton from "./OptionsButton";
import dayjs from "dayjs";
import IntegrationsModal from "./Modal/IntegrationsModal";
import DeleteActionModal from "./Modal/DeleteActionModal";
import { i18n } from '../../translate/i18n';
import { statusColor } from "../../pages/Sprint/utils/statusColor";
import { FCChip, FCTableContainer, FCTableHeader } from "components-fullcomm";

const columns = [
  {
    id: "name",
    label: i18n.t('sprint.list.actionsTable.name'),
    minWidth: 150,
  },
  {
    id: "category",
    label: i18n.t('sprint.list.actionsTable.quadrant'),
    minWidth: 150,
    sx: {
      color: "text.secondary",
    },
  },
  {
    id: "start",
    label: i18n.t('sprint.list.actionsTable.init'),
    minWidth: 100,
  },
  {
    id: "end",
    label: i18n.t('sprint.list.actionsTable.end'),
    minWidth: 100,
  },
  {
    id: "status",
    label: i18n.t('sprint.list.actionsTable.status'),
    minWidth: 100,
  },
  {
    id: "score",
    label: i18n.t('sprint.list.actionsTable.score'),
    minWidth: 100,
  },
  {
    id: "control",
    label: "",
    minWidth: 50,
  },
];

const Row = ({ action, selected, labelId, handleClick , user, toggleModal, i, handleValues, platform }) => {
  const [isItemSelected, setIsItemSelected] = useState(selected.find(el => el._id === action._id)?._id === action._id);
  if (action.perpetual) action.end = null

  useEffect(() => {
    setIsItemSelected(selected.find(el => el._id === action._id)?._id === action._id);
  }, [selected]);

  return (
    <TableRow key={action._id} role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox">
        <Checkbox
          color="backlog"
          onClick={(event) => handleClick(event, action)}
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      {columns.filter(el => {
          if (platform?.includes('suporte')) {
            return el.id !== 'category';
          }
          return true;
        }).map((column) => {
        let value;
        if (
          (column.id === "start" && action[column.id]) ||
          (column.id === "end" && action[column.id])
        ) {
          value = dayjs(action[column.id]).format("DD/MM/YYYY");
        } else {
          value =
            column.id === "category"
              ? `${action.category} - ${action.quadrant}`
              : action[column.id];
        }
        const { type } = statusColor(value);
        return column.id === "category" ||
          column.id === "status" ? (
          <TableCell key={`${column.id}-${action._id}`} sx={column.sx}>
            {
              value !== ' - ' && (
                <FCChip
                  color={type}
                  label={handleValues(value)}
                  size="small"
                />
              )
            }
          </TableCell>
        ) : column.id === "control" ? (
          <TableCell key={column.id} sx={column.sx} align="right">
            <OptionsButton user={user} action={action} toggleModal={toggleModal} i={i} platform={platform} />
          </TableCell>
        ) : (
          <TableCell key={column.id} sx={column.sx}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const ActionsTable = (props) => {
  const { platform } = props;
  const [actions, setActions] = useState([]);
  const [actionId, setActionId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openIntegrationsModal, setOpenIntegrationsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numSelected, setNumSelected] = useState(0);

  const deleteAction = async () => {
    setLoading(true);
    try {

      const result = await props.deleteAction(actionId, props.user._id);

      setLoading(false);
      setOpenModal(false);
      if (!result.error) {
        const filteredArray = actions.filter((action) => action._id !== actionId);
        setActions(filteredArray);
        if (result.message === "Ação apagada com sucesso!") {
          toast.success(i18n.t('sprint.list.actionsTable.deleteSuccess'))
        } else {
          toast.success(result.message);
        }
      } else {
        switch (result.error.message) {
          case "Ação não encontrada!": return toast.error(i18n.t('sprint.list.actionsTable.actionNotFound'));
          case "Não é possível excluir essa ação!": return toast.error(i18n.t('sprint.list.actionsTable.deleteError'));
          case "Erro ao atualizar ação!": return toast.error(i18n.t('sprint.list.actionsTable.updateError'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.list.actionsTable.internalError'));
          default: return toast.error(result.error.message);
        }
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.list.actionsTable.internalError'))
      } else {
        toast.error(error.error.message);
      }
    }
  };

  const toggleModal = (params = "", type) => {
    setActionId(params);
    if (type === "delete") {
      setOpenModal((prev) => !prev);
    } else {
      setOpenIntegrationsModal((prev) => !prev);
    }
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const filterParams = JSON.parse(localStorage.getItem('filterParams'));
      const params = new URLSearchParams(filterParams);
      params.set("limit", 9999);
      const actions = await props.getActions(params, false);
      const billingLogs = await props.getBillingLogs(actions?.docs[0]?.contract, { action: actions.docs.map(action => action._id).join(",") });
      const actionsWithBilling = actions.docs.map(action => {
        const billing = billingLogs?.reduce((acc, log) => {
          if (log.action !== action._id) return acc
          return acc += log.credits;
        }
        , 0);
        return {
          ...action,
          chargedAmount: billing
        }
      });
      props.setSelected(actionsWithBilling);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, action) => {
    const element = props.selected.find(el => el._id === action._id);
    const selectedIndex = props.selected.indexOf(element);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, action);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  };

  useEffect(() => {
    setNumSelected(props.selected.length);
  }, [props.selected]);

  useEffect(() => {
    setActions(props.actions?.docs.slice(0, 10));
  }, [props.actions]);

  const handleValues = (value) => {
    switch (value) {
      case 'Ativo': return i18n.t('sprint.list.actionsTable.active');
      case 'Backlog': return i18n.t('sprint.list.actionsTable.backlog');
      case 'Em andamento': return i18n.t('sprint.list.actionsTable.inProgress');
      case 'Concluído': return i18n.t('sprint.list.actionsTable.done');
      case 'Para fazer': return i18n.t('sprint.list.actionsTable.toDo');
      case 'Planejamento': return i18n.t('sprint.list.actionsTable.planning');
      case 'Cancelado': return i18n.t('sprint.list.actionsTable.canceled');
      case 'Ativação - Descoberta': return i18n.t('sprint.list.actionsTable.activationDiscovery');
      case 'Aquisição - Conteúdo': return i18n.t('sprint.list.actionsTable.acquisitionContent');
      case 'Engajamento - Próxima Compra': return i18n.t('sprint.list.actionsTable.engagementNextPurchase');
      case 'Conversão - Barreiras de Compra': return i18n.t('sprint.list.actionsTable.conversionBuyingBarriers');
      case 'Retenção - Análise de experiência': return i18n.t('sprint.list.actionsTable.retentionAnalysis');
      case 'Ativação - Consideração': return i18n.t('sprint.list.actionsTable.activationConsideration');
      case 'Aquisição - Rastreamento': return i18n.t('sprint.list.actionsTable.acquisitionTracking');
      case 'Engajamento - Contexto': return i18n.t('sprint.list.actionsTable.engagementContext');
      case 'Conversão - Ofertas': return i18n.t('sprint.list.actionsTable.conversionOffers');
      case 'Retenção - Transmissão': return i18n.t('sprint.list.actionsTable.retentionTransmission');
      case 'Ativação - Prospecção': return i18n.t('sprint.list.actionsTable.activationProspecting');
      case 'Aquisição - Benefício': return i18n.t('sprint.list.actionsTable.acquisitionBenefit');
      case 'Engajamento - Recuperação': return i18n.t('sprint.list.actionsTable.engagementRecovery');
      case 'Conversão - Autoridade': return i18n.t('sprint.list.actionsTable.conversionAuthority');
      case 'Retenção - Segmentos': return i18n.t('sprint.list.actionsTable.retentionSegments');
      default: return value;
    }
  }

  return (
    <>
        <FCTableContainer>
          <Table stickyHeader>
            <FCTableHeader sx={{ position: "sticky", top: 0, zIndex: 5 }}>
              <TableRow>
                <TableCell padding="checkbox" sx={{ background: "#F0F0F0" }}>
                  <Checkbox
                    color="backlog"
                    indeterminate={
                      numSelected > 0 && numSelected < actions?.length
                    }
                    checked={
                      actions?.length > 0 && numSelected >= actions?.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {columns.filter(el => {
                  if (props.platform?.includes('suporte')) {
                    return el.id !== 'category';
                  }
                  return true;
                }).map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ bgcolor: "#F0F0F0" }}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                    >
                      {column.id === "name" &&
                        <IconButton sx={{ padding: 0 }}>
                          <ArrowDownwardOutlinedIcon
                            onClick={() => props.setSortDirection((prev) => -prev)}
                          />
                        </IconButton>
                      }
                      <Typography fontWeight={"bold"}>
                        {column.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </FCTableHeader>
            <TableBody>
              {actions?.map((action, i) => 
                <Row 
                  key={action._id} 
                  action={action} 
                  selected={props.selected} 
                  labelId={`enhanced-table-checkbox-${i}`} 
                  handleClick={handleClick}
                  user={props.user}
                  toggleModal={toggleModal}
                  handleValues={handleValues}
                  platform={props.platform}
                  i={i}
                />
              )}
            </TableBody>
          </Table>
        </FCTableContainer>
      <IntegrationsModal
        actionId={actionId}
        open={openIntegrationsModal}
        setOpen={setOpenIntegrationsModal}
      />
      <DeleteActionModal
        toggleModal={toggleModal}
        openModal={openModal}
        deleteAction={deleteAction}
        loading={loading}
        setOpenModal={setOpenModal}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  actions: state.actions.actions,
  customer: state.customer.customerDetails,
  cycle: state.actions.cycle,
});

export default connect(mapStateToProps, { ...actions, ...actions2})(ActionsTable);
