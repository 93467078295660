import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import * as insightsActions from '../../../store/actions/insights';
import * as customerActions from '../../../store/actions/customers';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import axios from 'axios';
import { format } from 'date-fns';
import { Typography, Box, Tab, Stack, Dialog, Card, CardContent, Tooltip, IconButton, Container, Grid, Chip, Button } from '@mui/material';
import CurveABCTable from '../../../components/Insights/CurveABCTable';
import CurveRiskStockTable from '../../../components/Insights/CurveRiskStockTable';
import { LoadingButton } from '@mui/lab';
import LevelTypes from '../../../components/Insights/LevelTypes';
import MultipleYAxis from '../../../components/Insights/MultipleYAxis';
import InfoIcon from '@mui/icons-material/Info';
import { AccessTime, TrendingUp, Assessment, CheckCircle, BarChart } from '@mui/icons-material';
import CsvDownloadButton from 'react-json-to-csv';

import { apiMetrics, keyCrypto } from "../../../config"
import { FCTabs, FCTextField } from 'components-fullcomm';
const CurveABC = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingMin, setLoadingMin] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 'yyyy-MM-dd'));
  const [firstTab, setFirstTab] = useState(0);
  const [tab, setTab] = useState(1);
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [noInstall, setNoInstall] = useState(false);
  const [filterData, setFilterData] = useState();
  const [query, setQuery] = useState('');
  const [replace, setReplace] = useState(false);

  useEffect(async () => {
    props.getViewCustomer(props.shop);
  }, [props.shop])

  useEffect(async () => {
    if (props.token) {
      await search();
      setLoadingMaster(false)
    }
  }, [props.token])


  useEffect(async () => {
    if (props.customer) {
      if (props.customer.platformDomain === "experiencia-checkstore.myshopify.com") {
        setReplace(true);
        props.customer.platformDomain = "care-natural-beauty-cosmeticos.myshopify.com";
      }
      props.fetchToken(props.customer.platformDomain)

      try {
        const config_request = {
          method: "get",
          url: `${apiMetrics}/session/offline_${props.customer.platformDomain}`,
          headers: {
            'fullcomm-token': keyCrypto
          }
        };
        const result = (await axios(config_request)).data;
        if (!result?.id) {
          setNoInstall(true)
          setLoadingMaster(false)
        } else {
          setNoInstall(false)
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  }, [props.customer])

  useEffect(() => {
    if (query.length > 0) {
      const aux = data.filter((item) => item.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || item.title_variant?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || item.sku?.toLowerCase().includes(query.toLowerCase()) || item.product_type?.toLowerCase().includes(query.toLowerCase()));
      setFilterData(aux);
    } else {
      setFilterData(data);
    }
  }, [query, data])

  const search = async () => {
    if (props.token) {
      setLoadingMin(true)
      props.getInventoryLevelTypes(props.token, startDate, endDate);
      props.getCurveABC(props.token, endDate, startDate);
    }
  };

  useEffect(() => {
    if (props.curveABC) {
      setLoading(false)
      setLoadingMin(false)
      if (firstTab === 0) {
        if (tab === 0) setData(props.curveABC.abc_curve)
        else if (tab === 1) setData(props.curveABC.abc_curve_product)
        else if (tab === 2) setData(props.curveABC.abc_curve_type)

      } else if (firstTab === 1) {
        if (tab === 0) {
          const aux = [...props.curveABC.abc_curve].sort((a, b) => a.day_finish - b.day_finish);
          setData(aux)
        } else if (tab === 1) {
          const aux = [...props.curveABC.abc_curve_product].sort((a, b) => a.day_finish - b.day_finish);
          setData(aux)
        } else if (tab === 2) {
          const aux = [...props.curveABC.abc_curve_type].sort((a, b) => a.day_finish - b.day_finish);
          setData(aux)
        }
      }
    }
  }, [props.curveABC, tab, firstTab])

  const showDetails = async ({ option, product, type }) => {
    setLoadingDialog(true);
    setOpen(true);
    await props.getInventoryLevel(props.token, startDate, endDate, '', option, product, type)

    setLoadingDialog(false);
  }

  if (loadingMaster) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        <Spinner loading={loadingMaster} />
      </div>
    )
  }

  if (noInstall) {
    const appIcons = [
      <AccessTime />,
      <TrendingUp />,
      <Assessment />,
      <CheckCircle />,
      <BarChart />,
    ];

    const appBenefits = [
      i18n.t('curveABC.noInstall.benefit1'),
      i18n.t('curveABC.noInstall.benefit2'),
      i18n.t('curveABC.noInstall.benefit3'),
      i18n.t('curveABC.noInstall.benefit4'),
      i18n.t('curveABC.noInstall.benefit5')
    ];
    return (
      <Container maxWidth="md" style={{ marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>

          {i18n.t('curveABC.noInstall.title')} <Chip label={i18n.t('curveABC.noInstall.new')} color="success" />
        </Typography>
        <Grid container spacing={2} mt={2}>
          {appIcons.map((icon, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  <div style={{ textAlign: 'center' }}>{icon}</div>
                  <br />
                  <Typography variant="body2">
                    {appBenefits[index]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body2" style={{ marginTop: '20px' }}>
          {i18n.t('curveABC.noInstall.text1')}
          <br />
          {i18n.t('curveABC.noInstall.text2')}
          <br />
          {i18n.t('curveABC.noInstall.text3')}
        </Typography>
        <br />
        <Box fullWidth style={{ textAlign: 'center' }}>
          <a href='https://apps.shopify.com/metricas-fullcomm?locale=pt-BR' target='_blank'>
            <Button style={{ backgroundColor: 'green' }} variant='contained'>
              {i18n.t('curveABC.noInstall.unblock')}</Button>
          </a>
        </Box>
      </Container>
    );
  }

  const getExportData = () => {
    switch (tab) {
      case 0:
        return props.curveABC?.abc_curve
      case 1:
        return props.curveABC?.abc_curve_product
      case 2:
        return props.curveABC?.abc_curve_type
    }
  }

  const itemHandle = (item) => {
    switch (tab) {
      case 0:
        return {
          'Título': item.title,
          'SKU': item.sku,
          'Tipo': item.product_type,
          'Quantidade': item.quantity,
          'Faturamento': item.amount.toFixed(2).toString().replace('.', ','),
          '%': item.percent.toFixed(2).toString() + '%',
          'Estoque': item.avaiable,
          'Valor_em_estoque': item.value_stock,
          'Esgotamento': format(new Date(item.date_finish), "dd/MM/yyyy"),
          'Dias_para_esgotar': item.day_finish,
          'Curva_ABC': item.abc,
          'Média_por_dia': item.media_day.toFixed(2).toString().replace('.', ','),
        }
      case 1:
        return {
          'Título': item.title,
          'Tipo': item.product_type,
          'Quantidade': item.quantity,
          'Faturamento': item.amount.toFixed(2).toString().replace('.', ','),
          '%': item.percent.toFixed(2).toString() + '%',
          'Estoque': item.avaiable,
          'Valor_em_estoque': item.value_stock,
          'Esgotamento': format(new Date(item.date_finish), "dd/MM/yyyy"),
          'Curva_ABC': item.abc,
        }
      case 2:
        return {
          'Tipo': item.product_type,
          'Quantidade': item.quantity,
          'Faturamento': item.amount.toFixed(2).toString().replace('.', ','),
          '%': item.percent.toFixed(2).toString() + '%',
          'Estoque': item.avaiable,
          'Valor_em_estoque': item.value_stock,
          'Esgotamento': format(new Date(item.date_finish), "dd/MM/yyyy"),
          'Curva_ABC': item.abc,
        }
    }
  }

  const getFileName = () => {
    let type;

    if (tab === 0) type = 'Variação'
    if (tab === 1) type = 'Produto'
    if (tab === 2) type = 'Tipo'

    return `Inventário_Relatório_Por_${type}_${startDate}_${endDate}.csv`
  }

  const getExportDataHandled = () => {
    let dataToExport = getExportData();
    dataToExport = dataToExport?.map((item) => {
      if (item.title) item.title = item.title.replace('"', "")
      return itemHandle(item)
    })
    return dataToExport
  }
  const exportData = getExportDataHandled();

  return (
    <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" gutterBottom>
          {i18n.t('insight.title')}
        </Typography>
        {exportData?.length > 0 &&
          <CsvDownloadButton data={exportData} style={{ border: 'none', background: 'none', cursor: 'pointer' }} filename={getFileName()}><Icon width={24} icon="ph:export" /></CsvDownloadButton>
        }
      </div>
      <Stack direction="row" justifyContent="flex-start" alignItems="end" spacing={2} mt={2} >
        <FCTextField value={startDate} onChange={e => setStartDate(e.target.value)} id="date" label={i18n.t('insight.startDate')} type="date" />
        <FCTextField value={endDate} onChange={e => setEndDate(e.target.value)} id="date" label={i18n.t('insight.endDate')} type="date" />
        <LoadingButton onClick={() => search()} loading={loadingMin} variant="contained" className='btn-white medium mb-1' >{i18n.t('insight.search')}</LoadingButton>
      </Stack>
      <br />
      {data &&
        <>
          <p>
            {i18n.t('curveABC.totalSales')}:
            {loadingMin ? <><Spinner style={{ width: '20px', height: '20px', marginLeft: '10px' }} /></> : <b> R${props.curveABC?.total_price?.toFixed(2)}</b>}
          </p>
          <FCTabs variant='fullWidth' onChange={(event, newValue) => setFirstTab(newValue)} value={firstTab} >
            <Tab label={i18n.t('insight.curveABC')} />
            <Tab label={i18n.t('insight.riskStock')} />
            <Tab label={i18n.t('curveABC.stockPerTypeProducts')} />
          </FCTabs>

          {firstTab !== 2 &&
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FCTabs onChange={(event, newValue) => setTab(newValue)} value={tab} >
                  <Tab label={i18n.t('insight.variant')} />
                  <Tab label={i18n.t('insight.product')} />
                  <Tab label={i18n.t('insight.type')} />
                </FCTabs>
              </Box>
              <Stack direction="row" spacing={2} mt={2} mb={2} >
                <FCTextField value={query} onChange={e => setQuery(e.target.value)} label={i18n.t('insight.search')} />
              </Stack>
            </>
          }
          {loadingMin ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Spinner loading={loading} />
          </div> :
            <>
              {firstTab === 0 &&
                <CurveABCTable showDetails={showDetails} tab={tab} data={filterData} replace={replace} />}
              {firstTab === 1 &&
                <CurveRiskStockTable showDetails={showDetails} tab={tab} data={filterData} replace={replace} />}
              {firstTab === 2 &&
                <LevelTypes inventory={props.inventoryLevelTypes?.inventory} dates={props.inventoryLevelTypes?.dates} />
              }

            </>
          }
        </>
      }
      <Dialog maxWidth={'xl'} fullWidth onClose={() => setOpen(false)} open={open}>
        {
          loadingDialog ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <Spinner loading={loading} />
          </div> :
            <>                        {props.inventoryLevel?.inventory?.length > 0 &&
              <>
                <div style={{ position: 'relative', margin: '10px 20px' }}>
                  <Card style={{
                    color: 'white', backgroundColor: props.inventoryLevel?.expected?.curve >= 200 || props.inventoryLevel?.expected?.curve < 50 ? '#e74c3c' : props.inventoryLevel?.expected?.curve >= 150 || props.inventoryLevel?.expected?.curve < 50 ? '#f1c40f' : '#2ecc71',
                  }}>
                    <CardContent>
                      <Typography>
                        <b>{i18n.t('insight.curve')}</b>
                      </Typography>
                      <Typography variant="body2">
                        {props.inventoryLevel?.expected?.curve} %
                      </Typography>
                    </CardContent>
                  </Card>
                  <Tooltip title={<>{i18n.t('curveABC.noInstall.between100')}<br />{i18n.t('curveABC.noInstall.major100')}</>}>
                    <IconButton
                      aria-label={i18n.t('insight.info')}
                      style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <MultipleYAxis inventory={props.inventoryLevel?.inventory} total={props.inventoryLevel?.total} />
              </>}
            </>

        }
      </Dialog>

    </div>
  );

};


const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  curveABC: state.insight.curveABC,
  inventoryLevel: state.insight.inventoryLevel,
  inventoryLevelTypes: state.insight.inventoryLevelTypes,
})

export default connect(mapStateToProps, { ...insightsActions, ...customerActions })(CurveABC);

