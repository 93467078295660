import React from "react";
import ReactApexChart from "react-apexcharts";
import { chartLocale } from './locale'

function BarOrders({ orders, height }) {
    if (!orders) return null
    height = height || 500
    const ordersMap = orders?.map(elem => elem.current_total_price.toFixed(2));
    const dateMap = orders?.map(elem => elem.date);
    const data = {
        series: [{
            name: 'Faturamento',
            data: ordersMap,
        }],
        options: {
            chart: {
                locales: [chartLocale],
                defaultLocale: 'global',
                height: height,
                type: 'bar',
                toolbar: {
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                    }
                },
            },
            xaxis: {
                type: 'datetime',
                categories: dateMap,
                show: false
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value >= 1000000) {
                            return `R$ ${(value / 1000000).toLocaleString('pt-BR')}M`;
                        } else if (value >= 1000) {
                            return `R$ ${(value / 1000).toLocaleString('pt-BR')}k`;
                        } else {
                            return `R$ ${value.toLocaleString('pt-BR')}`;
                        }
                    }
                },
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return `R$ ${value.toLocaleString('pt-BR')}`
                    }
                },
            }
        },
    };


    return (
        <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="bar" height={height} />
        </div>
    );
}

export default BarOrders;
